import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import clsx from "clsx";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import "./PoolTips.scss";

export default function PoolTips({ data, location }) {
  const [selectedPoolTipID, setSelectedPoolTipID] = React.useState(null);
  const pathName = location.pathname.split("/")[1];

  const scrollElement = React.useRef(null);

  const {
    markdownRemark: {
      frontmatter: { poolTips },
    },
  } = data;

  const setSelectedPoolTip = (poolTipID) => {
    if (selectedPoolTipID === poolTipID) {
      setSelectedPoolTipID(-1);
      return;
    }

    setSelectedPoolTipID(poolTipID);
    setTimeout(
      () => {
        if (window.innerWidth < 576) {
          scrollElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },
      100,
    );
  };

  const isSelected = (poolTipID) => {
    return selectedPoolTipID === poolTipID;
  };

  const selectedPoolTip = poolTips.find((poolTip) => poolTip.poolTipID === selectedPoolTipID);

  return (
    <Layout pathName={pathName}>
      <Container fluid>
        <Row>
          <Col xs={12} sm={5}>
            <div>
              <table className="table table-striped table-bordered table-hover table-condensed">
                <tbody>
                  {poolTips.map((poolTip) => (
                    <tr key={poolTip.poolTipID}>
                      <td style={{ verticalAlign: "middle" }}>{poolTip.poolTipName}...</td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        <Button
                          variant="info"
                          onClick={() => setSelectedPoolTip(poolTip.poolTipID)}
                          className={clsx(
                            { selected: isSelected(poolTip.poolTipID) },
                            "btn-custom btn-info-custom",
                          )}
                        >
                          Read More
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>

          <Col xs={12} sm={7} ref={scrollElement}>
            {selectedPoolTip && (
              <Card border="primary">
                <Card.Header className="card-header-custom">
                  <h3 className="card-title-custom">{selectedPoolTip.poolTipName}</h3>
                </Card.Header>

                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: selectedPoolTip.poolTipContent }} />
                </Card.Body>

                <Card.Footer>&nbsp;</Card.Footer>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

PoolTips.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

PoolTips.defaultProps = {
  data: null,
  location: null,
};

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/pages/6-PoolTips/" } }) {
      html
      frontmatter {
        poolTips {
          poolTipID
          poolTipName
          poolTipContent
        }
      }
    }
  }
`;
